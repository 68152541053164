import * as gr_challenge_1 from './challenges/gr_challenge_1.json';
import * as gr_challenge_2 from './challenges/gr_challenge_2.json';
import assessments from './el.json';
import * as enTranslations from './en.json';

export default {
  ...enTranslations,
  ...assessments,
  ...gr_challenge_1.translations,
  ...gr_challenge_2.translations,
};
