import { OthersOptions } from '@app/@types/redux/pageElement';
import { PageElement } from '@app/@types/redux/project';
import { i18nCText } from '@libs/i18n/I18n';
import { useAppSelector } from '@state/redux/store';
import classnames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import EllipsisText from 'react-ellipsis-text/lib/components/EllipsisText';
import { GrAttachment } from 'react-icons/gr';
import { HiDotsVertical, HiDownload, HiPrinter, HiStar, HiTrash } from 'react-icons/hi';
import { LuFileSpreadsheet } from 'react-icons/lu';
import replace from 'react-string-replace';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import NoStyleDiv from '../../../../UI/Html/NoStyleDiv';
import './InboxPage.scss';

interface InboxPageProps {
  pageElement: PageElement;
}

const InboxPage: React.FC<InboxPageProps> = (props) => {
  const { pageElement } = props;

  const { id } = pageElement;

  const emails = (pageElement.attributes.options as OthersOptions).emails;
  const name = useAppSelector((state) => state.users.user.attributes.name);

  const [tabs, setTabs] = useState(0);

  function toggleNavs(e: MouseEvent, index: number) {
    e.preventDefault();
    setTabs(index);
  }

  return (
    <Row className="mt-4 ">
      <Col md={4} className="emailList__mainContainer">
        <Nav className="emailList__nav" role="tablist" vertical>
          {emails.map((email, index) => {
            return (
              <NavItem key={`${id}${email.id}`}>
                <NavLink
                  aria-selected={tabs === index}
                  className={classnames('emailList__item mb-sm-3 mb-md-0', {
                    active: tabs === index,
                    first: index === 0,
                  })}
                  onClick={(e: MouseEvent) => {
                    toggleNavs(e, index);
                  }}
                  href="#pablo"
                  role="tab"
                >
                  <div className="emailList__container">
                    <div>
                      <img
                        alt={email.sender.name}
                        src={email.sender.image}
                        className="emailList__senderImage"
                      />
                    </div>
                    <div>
                      <h4 className="emailList__senderName">
                        <span>{email.sender.name}</span>
                      </h4>
                      <h5 className="emailList__subject">
                        <EllipsisText text={i18nCText(email.subject)} length={24} />
                      </h5>
                    </div>
                  </div>
                  <div>
                    <h5 className="emailList__dateTime text-muted">
                      {moment().format('DD MMM YYYY')}
                    </h5>
                    {email.sender.attachments?.length && email.sender.attachments.length > 0 ? (
                      <div className="emailList__attachment">
                        <GrAttachment size={16} color="#8898aa" />
                      </div>
                    ) : null}
                  </div>
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
      </Col>
      <Col md={8} className="emailPage__wrapper">
        <div>
          <TabContent>
            {emails.map((email, index) => {
              if (tabs === index) {
                const body = replace(i18nCText(email.body), `{{{recipientName}}}`, () => {
                  return name;
                }).join('');
                return (
                  <TabPane key={`${id}${email.id}`}>
                    <div className="emailPage__container">
                      <div className="emailPage__header">
                        <h5 className="emailPage__subject">{i18nCText(email.subject)}</h5>
                        <div className="emailPage__icons">
                          <HiStar />
                          <HiPrinter />
                          <HiTrash />
                          <HiDotsVertical />
                        </div>
                      </div>
                      <div className="emailPage__header">
                        <div className="d-flex align-items-center ">
                          <img
                            alt={email.sender.name}
                            src={email.sender.image}
                            className="emailPage__senderImage"
                          />
                          <div className="">
                            <h4 className="emailPage__senderName">{email.sender.name}</h4>
                            <span className="emailPage__senderEmail text-muted ">
                              {email.sender.email}
                            </span>
                          </div>
                        </div>
                        <div>
                          <h5 className="emailPage__dateTime  text-muted">
                            {moment().format('DD MMM YYYY')}
                          </h5>
                        </div>
                      </div>
                      <div className="emailPage__body">
                        <div className="emailPage__body__container">
                          <div className="emailPage__body__text">
                            <NoStyleDiv>{body}</NoStyleDiv>
                          </div>
                        </div>
                      </div>
                    </div>
                    {email.sender.attachments?.length && email.sender.attachments.length > 0 ? (
                      <div className="emailPage__attachments">
                        <h5 className="emailPage__attachments__title">
                          Attachments ({email.sender.attachments.length})
                        </h5>
                        <div className="emailPage__attachments__container">
                          {email.sender.attachments.map((attachment) => {
                            return (
                              <div key={attachment.id} className="emailPage__attachments__item">
                                <div rel="noreferrer" className="emailPage__attachments__link">
                                  <div className="emailPage__attachments__content">
                                    <LuFileSpreadsheet
                                      className="emailPage__attachments__icon"
                                      size={32}
                                    />

                                    <EllipsisText
                                      className="emailPage_text"
                                      text={attachment.name}
                                      length={12}
                                    />
                                  </div>
                                  <HiDownload
                                    onClick={() => window.open(attachment.attachment, '_blank')}
                                    className="emailPage__attachments__icon--end"
                                    size={26}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </TabPane>
                );
              }
              return null;
            })}
          </TabContent>
        </div>
      </Col>
    </Row>
  );
};

export default InboxPage;
