import zod from 'zod';

const schema = zod.object({
  VITE_APP_URL: zod.string().min(1),
  VITE_API_URL: zod.string().min(1),
  VITE_CREATION_PLATFORM_URL: zod.string().min(1),
  VITE_TEACHER_PLATFORM_URL: zod.string().min(1),
  VITE_MINIMUM_GET_REVIEWS: zod.number().int().min(1).default(3),
  VITE_MINIMUM_GIVE_REVIEWS: zod.number().int().min(1).default(3),
  VITE_API_VERSION: zod.string().min(1).default('v1'),
  VITE_TINY_MCE_API_KEY: zod.string().min(1),
  VITE_ROLLBAR_ENV: zod.string().min(1),
  VITE_ROLLBAR_TOKEN: zod.string().min(1),
  VITE_GA_TRACKING_ID: zod.string().min(1),
});

const envVerified = schema.parse(import.meta.env);

const env = {
  APP_URL: import.meta.env.BASE_URL || envVerified.VITE_APP_URL,
  API_URL: envVerified.VITE_API_URL,
  CREATION_PLATFORM_URL: envVerified.VITE_CREATION_PLATFORM_URL,
  TEACHER_PLATFORM_URL: envVerified.VITE_TEACHER_PLATFORM_URL,
  MINIMUM_GET_REVIEWS: envVerified.VITE_MINIMUM_GET_REVIEWS,
  MINIMUM_GIVE_REVIEWS: envVerified.VITE_MINIMUM_GIVE_REVIEWS,
  API_VERSION: envVerified.VITE_API_VERSION,
  TINY_MCE_API_KEY: envVerified.VITE_TINY_MCE_API_KEY,
  ROLLBAR_ENV: envVerified.VITE_ROLLBAR_ENV,
  ROLLBAR_TOKEN: envVerified.VITE_ROLLBAR_TOKEN,
  DEV: import.meta.env.DEV,
  PROD: import.meta.env.PROD,
  GA_TRACKING_ID: envVerified.VITE_GA_TRACKING_ID,
};

export default env;
